<div class="skillsmates-box-shadow card" style="padding-bottom: 5px;">
  <div class="row card-header skillsmates-box-top" style="margin: 0 1px 0 1px; cursor: pointer;" (click)="onClick()">

    <div class="col-md-12 no-margin-padding  collapsed d-block" data-toggle="collapse" href="#collapse-collapsed"
         aria-expanded="true" aria-controls="collapse-collapsed" id="heading-collapsed">
      <em class="fa fa-chevron-down pull-right" style="padding-top: 10px; padding-left: 5px;"></em>
      <img height="24" src="{{'forum' | image}}" alt=""> &nbsp;
      <strong [routerLink]="[Route.FORUM]">FORUM</strong> ({{forums.length}})
    </div>

  </div>
  <div class="row bg-white" style="padding: 10px 0 10px 0; margin: 0; border-radius: 0 0 10px 10px;" *ngIf="expand">
    <div class="col">
      <div class="text-center" *ngFor="let forum of forums; let i = index">
        <sm-forum-account [forum]="forum" [isLastOne]="forums.length === i+1"></sm-forum-account>
      </div>
    </div>
  </div>
</div>

