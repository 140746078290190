import {Component, Input, OnInit} from '@angular/core';
import {MediaType} from "../../models/mediaType";
import {MediaHelper} from "../../helpers/media-helper";
import {DocumentTypeResource, ParameterService, PostDetailsResource, PostFileService} from "ApiModulePost";
import {PostRequest} from "../../models/requests/postRequest";
import {Alert} from "../../models/alert";
import {AccountDetailsResource} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import {ToastrService} from "ngx-toastr";
import {Route} from "../../enums/route.enum";
import {Router} from "@angular/router";
import {variables} from "../../../environments/variables";
import {v4 as uuid} from 'uuid';
import {DownloadService} from "../../services/download.service";
import {DomSanitizer} from "@angular/platform-browser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: 'app-edit-post',
  templateUrl: './edit-post.component.html',
  styleUrls: ['./edit-post.component.scss']
})
export class EditPostComponent implements OnInit {

  @Input() post!: PostDetailsResource;

  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  alert: Alert = {} as Alert;
  image!: string | ArrayBuffer | null;
  selectedFile!: File | null;
  mediaTypes: MediaType[] = MediaHelper.mediaTypes;
  selectedMediaType: MediaType = {} as MediaType;
  documentTypes: DocumentTypeResource[] = [];
  selectedDocumentTypesList: string[] = [];
  selectedDocumentTypesString!: string;
  selectedDocumentTypes: DocumentTypeResource[] = [];
  selectedDocumentType!: DocumentTypeResource;
  processing: boolean = false;
  postRequest: PostRequest = {} as PostRequest;
  showErrorMessageTitle: boolean = false;
  inputStatusTitle: string = "";
  showErrorMessageLink: boolean = false;
  inputStatusLink: string = "";
  showErrorMessageFile: boolean = false;
  inputStatusFile: string = '';
  showErrorMessageDocumentType: boolean = false;
  inputStatusDocumentType: string = "";
  defaultDocumentType: string = "Choisir un type de document";
  isMediaFile: boolean = false;
  isMediaLink: boolean = false;
  previewLink: string = "";
  titleMaxLength: number = 100
  KeywordMaxLength: number = 255
  descriptionMaxLength: number = 2000
  maxFileSize: number = 15
  public Editor = ClassicEditor;

  constructor(private postFileService: PostFileService,
              private downloadService: DownloadService,
              private sanitizer: DomSanitizer,
              private localStorageService: LocalStorageService,
              private toastrService: ToastrService,
              private router: Router,
              private postParameterService: ParameterService) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.post = this.localStorageService.get(variables.post_update);
    this.selectedMediaType = this.localStorageService.get(variables.current_media_type);
    if (!this.selectedMediaType) {
      this.selectedMediaType = this.mediaTypes[0];
    }
    this.findDocumentTypes();
    this.populatePost();
    const id: string = uuid();
  }

  populatePost(): void {
    if (this.post) {
      this.postRequest.account = this.post.account?.uuid;
      this.postRequest.uuid = this.post.uuid;
      this.postRequest.title = this.post.title;
      this.postRequest.keywords = this.post.keywords;
      this.postRequest.description = this.post.description;
      this.postRequest.documentType = this.post.documentType;
      this.postRequest.link = this.post.link;

      this.downloadService.downloadImage(this.post.link!).subscribe({
        next: value => {
          const arrayOfBlob = new Array<Blob>();
          arrayOfBlob.push(new Blob([value]));
          if (this.post && this.post.title && this.post.link) {
            this.selectedFile = new File(arrayOfBlob, this.post.title! + "." + this.post.link.split(".").pop());
          } else {
            this.selectedFile = new File(arrayOfBlob, variables.no_filename + ".txt");
          }
          this.postRequest.file = this.selectedFile;
          this.previewImage();
        },
        error: err => {
        },
        complete: () => {
        }
      });

      this.localStorageService.delete(variables.post_update);
    }
  }

  fileSize() {
    return this.selectedFile ? (this.selectedFile.size / (1024 * 1024)) : 0
  }

  sizeDisplayText() {
    return this.fileSize().toFixed(1) + "Mb/" + this.maxFileSize + "Mb"
  }

  populateDocumentType(): void {
    this.mediaTypes.forEach(value => {
      if (value.code == this.post.mediaType) {
        this.selectedMediaType = value;
      }
    });
    this.documentTypes.forEach(document => {
      if (document.code == this.post.documentType) {
        this.selectedDocumentType = document;
        this.selectedDocumentTypesString = document.label!;
      }
    });
  }

  findDocumentTypes(): void {
    this.postParameterService.getDocumentTypes().subscribe({
      next: response => {
        this.documentTypes = response.resources!;
        if (this.post) {
          this.populateDocumentType();
        }
        this.loadDocumentTypesDropdown(this.selectedMediaType);
      },
      error: error => {

      },
      complete: () => {
      }
    });
  }

  loadDocumentTypesDropdown(mediaType: MediaType) {
    this.selectedDocumentTypesList = [this.defaultDocumentType];
    this.selectedDocumentTypes = [];
    this.documentTypes.forEach(value => {
      if (value.mediaType && value.mediaType.code == mediaType.code) {
        this.selectedDocumentTypes.push(value);
        this.selectedDocumentTypesList.push(value.label!);
      }
    });
    if (!this.selectedDocumentTypesString) {
      this.selectedDocumentTypesString = this.selectedDocumentTypesList[0];
    }
  }

  onClickMediaType(mediaType: MediaType): void {
    this.selectedMediaType = mediaType;
    this.loadDocumentTypesDropdown(mediaType);
  }

  getDocumentType(event: string): void {
    this.selectedDocumentTypes.forEach(value => {
      if (value.label != this.defaultDocumentType && value.label === event) {
        this.selectedDocumentType = value;
        this.postRequest.documentType = value.code;
      }
    });
  }

  getTitle(e: any): void {
    this.postRequest.title = e;
  }

  getKeywords(e: any): void {
    this.postRequest.keywords = e;
  }

  getDescription(e: any): void {
    this.postRequest.description = e;
  }

  onClickCancel(): void {
    this.postRequest = {} as PostRequest;
    this.selectedFile = null;
    this.image = null;
    this.isMediaLink = false;
    this.isMediaFile = false;
    this.router.navigate([Route.PROFILE]);
  }

  onClickPublish(): void {
    this.handleFile()
    if (this.isSelectedFileValid() && this.validatePost().length <= 0) {
      if (this.post) {
        this.sendPostUpdateRequest()
      } else {
        this.sendPostAddRequest()
      }
    } else {
      this.alert = {
        display: true,
        class: 'danger',
        title: 'Erreur',
        message: 'Certains champs sont obligatoires ou invalides'
      }
    }
  }

  getLink(event: string): void {
    if (event) {
      this.postRequest.link = event;
      this.isMediaLink = true;
      this.isMediaFile = false;

      this.downloadService.extractLink(this.postRequest.link!).subscribe({
        next: value => {
          this.previewLink = value.images[0];
        },
        error: err => {
        },
        complete: () => {
        }
      });
    }
  }

  getFile(event: File): void {
    if (event) {
      this.selectedFile = event;
      this.postRequest.file = event;
      this.isMediaLink = false;
      this.isMediaFile = true;
      this.previewImage();
    }
  }

  isSelectedFileValid() {
    return (this.fileSize() <= this.maxFileSize)
  }

  onFileSelected(event: any) {
    let file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
      this.postRequest.file = file;
      this.isMediaLink = false;
      this.isMediaFile = true;
      this.previewImage();
    }
  }

  previewImage(): void {
    if (this.selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = (event) => {
        this.image = reader.result;
      };
    }
  }

  validatePost(): string[] {
    let errors: string[] = []
    if (!this.postRequest.title) {
      errors.push("TITLE");
      this.showErrorMessageTitle = true;
      this.inputStatusTitle = 'invalid';
    } else {
      this.showErrorMessageTitle = false;
      this.inputStatusTitle = 'valid';
    }
    if (!this.postRequest.documentType) {
      errors.push("DOCUMENT_TYPE");
      this.showErrorMessageDocumentType = true;
      this.inputStatusDocumentType = 'invalid';
    } else {
      this.showErrorMessageDocumentType = false;
      this.inputStatusDocumentType = 'valid';
    }

    if (!this.postRequest.keywords) {
      this.postRequest.keywords = "   ";
    }

    if (!this.postRequest.description) {
      this.postRequest.description = "   ";
    }

    if (errors.length > 0) {
      this.alert = {
        display: true,
        class: 'danger',
        title: 'Erreur',
        message: 'Certains champs sont obligatoires'
      }
    }

    return errors;
  }

  initErrors() {
    this.alert = {} as Alert;
    this.showErrorMessageTitle = false;
    this.inputStatusTitle = '';
    this.showErrorMessageDocumentType = false;
    this.inputStatusDocumentType = '';
    this.showErrorMessageLink = false;
    this.inputStatusLink = '';
    this.showErrorMessageFile = false;
    this.inputStatusFile = '';
  }

  private sendPostAddRequest() {
    this.processing = true
    this.postFileService.savePost(
      this.postRequest.uuid ? this.postRequest.uuid : uuid(),
      this.postRequest.title!,
      this.postRequest.keywords!,
      this.postRequest.description!,
      this.postRequest.documentType!,
      "POST_TYPE_ARTICLE",
      this.postRequest.link ?? 'link',
      this.loggedAccount.uuid!,
      this.postRequest.file!).subscribe({
      next: response => {
        this.toastrService.success("La publication a été créé avec succés", 'Création publication');
        this.initErrors();
        this.localStorageService.update(variables.profile_account, this.loggedAccount);
        this.processing = false
        this.router.navigate([Route.PROFILE]);
      },
      error: error => {
        this.processing = false
        this.toastrService.error("Erreur lors de la création de la publication", 'Création publication');
      },
      complete: () => {
        this.processing = false
      }
    });
  }

  private sendPostUpdateRequest() {
    this.processing = true
    this.postFileService.updatePost(
      this.postRequest.uuid!,
      this.postRequest.title!,
      this.postRequest.keywords!,
      this.postRequest.description!,
      this.postRequest.documentType!,
      "POST_TYPE_ARTICLE",
      this.postRequest.link!,
      this.postRequest.account!,
      this.postRequest.file!).subscribe({
      next: response => {
        this.initErrors();
        this.toastrService.success("La publication a été mis à jour avec succés", 'Mis à jour publication');
        this.localStorageService.update(variables.profile_account, this.loggedAccount);
        this.processing = false
        this.router.navigate([Route.PROFILE]);
      },
      error: error => {
        this.processing = false
        this.toastrService.error("Erreur lors de la mise à jour de la publication", 'Mis à jour publication');
      },
      complete: () => {
        this.processing = false
      }
    });
  }

  private handleFile() {
    if (!this.selectedFile) {
      const arrayOfBlob = new Array<Blob>();
      arrayOfBlob.push(new Blob([''], {type: 'application/txt'}));
      if (this.post && this.post.link) {
        this.selectedFile = new File(arrayOfBlob, variables.no_filename + "." + this.post.link.split(".").pop());
      } else {
        this.selectedFile = new File(arrayOfBlob, variables.no_filename + ".txt");
      }
    }
    this.postRequest.file = this.selectedFile;
  }
}
