import {Component, OnInit} from '@angular/core';
import {AuthorQuote} from "../../models/authorQuote";
import {PartnerData} from "../../models/partnerData";
import {Route} from "../../enums/route.enum";

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent  implements OnInit {

  partners : PartnerData [] = []

  constructor() {
  }

  ngOnInit(): void {
    this.initPartners()
  }

  initPartners(){
    this.partners.push(new PartnerData("essdyjewel.jpeg", "http://www.essdyjewel.com"))
    this.partners.push(new PartnerData("reburnfit2.jpeg", "http://www.reburnfit2.com"))
    this.partners.push(new PartnerData("skillsmates1.jpeg", "http://www.skills-mates.com"))
    this.partners.push(new PartnerData("skillsmates2.jpeg", "http://www.skills-mates.com"))
    this.partners.push(new PartnerData("skillsmates3.jpeg", "http://www.skills-mates.com"))
  }

    protected readonly Route = Route;
}
