import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {variables} from "../../../environments/variables";
import {LocalStorageService} from "../../services/local-storage.service";
import {Route} from "../../enums/route.enum";
import {InteractionCreateRequest, InteractionService, PostDetailsResource, PostService} from "ApiModulePost";
import {AccountDetailsResource} from "ApiModuleAccount";
import {ToastrService} from "ngx-toastr";
import {v4 as uuid} from 'uuid';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {NotificationsService} from 'src/app/services/notifications.service';
import {CommentsService} from 'src/app/services/comments.service';

@Component({
  selector: 'sm-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit, OnChanges, OnDestroy {
  @Input() post: PostDetailsResource = {} as PostDetailsResource;
  @Input() isSharedPost: boolean = false;
  @Output() newPostEvent = new EventEmitter<string>();
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  id: string | null = "";
  loading: boolean = false;
  typedComment: string = "";
  displayInteractionBlock: string = "";
  comments: PostDetailsResource[] = [];
  maxCommentSize: number = 500;
  errors: any = {};
  @ViewChild('content') popupview !: ElementRef;
  shareDescription: string = "";
  @Input() isShared: boolean = false;
  comment: boolean = false;
  like: boolean = false;
  private modalReference!: NgbModalRef;

  constructor(private postService: PostService,
              private interactionService: InteractionService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private toastrService: ToastrService,
              private modalService: NgbModal,
              private sanitizer: DomSanitizer,
              private storageService: LocalStorageService,
              private notificationsService: NotificationsService,
              private commentService: CommentsService) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.storageService.getLoggedAccount();
    this.id = this.activatedRoute.snapshot.paramMap.get(variables.id);
    if (!this.id && !this.storageService.isOffline()) {
      this.id = this.loggedAccount.uuid!;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
  }

  onClickLike(): void {
    if (this.loggedAccount) {
      let interaction: InteractionCreateRequest = {} as InteractionCreateRequest;
      interaction.uuid = "";
      interaction.active = true;
      interaction.entity = this.post.uuid;
      interaction.emitter = this.loggedAccount.uuid;
      interaction.receiver = this.post.account?.uuid;
      interaction.content = this.post.title;
      interaction.interactionType = 'INTERACTION_TYPE_LIKE';

      if (this.post.likes) {
        if (this.post.liked) {
          this.post.likes--;
        } else {
          this.post.likes++;
        }
      }

      this.post.liked = !this.post.liked;

      this.interactionService.saveInteraction(interaction)
        .subscribe({
          next: response => {
          },
          error: error => {
          },
          complete: () => {
          }
        });
    }
  }

  onClickComment(): void {
    this.displayInteractionBlock = this.displayInteractionBlock === "" ? "INTERACTION_TYPE_COMMENT" : "";
  }

  onClickPost(post: PostDetailsResource): void {
    this.storageService.add(variables.post_details, post);
    this.storageService.add(variables.previous_route, Route.DASHBOARD);
    this.router.navigate([Route.POST]);
  }

  keyupComment(e: any): void {
    if (e.keyCode === 13) {
      this.onClickPublishComment();
    }
  }

  onClickPublishComment(): void {
    let interaction: InteractionCreateRequest = {} as InteractionCreateRequest;
    interaction.content = this.typedComment.trim();
    if (this.validateComment(interaction).length <= 0) {
      this.loading = true;
      this.typedComment = "";
      interaction.uuid = uuid();
      interaction.active = true;
      interaction.emitter = this.loggedAccount.uuid;
      interaction.receiver = this.post.account?.uuid;
      interaction.entity = this.post.uuid;
      interaction.interactionType = 'INTERACTION_TYPE_COMMENT';

      if (this.post.comments) {
        this.post.comments++;
      }

      this.interactionService.saveInteraction(interaction)
        .subscribe({
          next: response => {
            this.loading = false;
            this.toastrService.success("Commentaire ajouté", "Commentaire");
            this.notificationsService.announceMission(this.loggedAccount.uuid!)
            this.commentService.announceMission(this.post.uuid!)
          },
          error: error => {
            this.loading = false;
            this.toastrService.error("Erreur lors de l'ajout du commentaire", "Commentaire");
          },
          complete: () => {
            this.loading = false;
          }
        });
    } else {
      this.toastrService.error(this.validateComment(interaction).toString(), "Commentaire");
    }
  }

  validateComment(interaction: InteractionCreateRequest): string[] {
    let errors: string[] = []
    if (!interaction.content) {
      errors.push("Le message est requis");
    }
    if (interaction.content !== undefined && interaction.content.length > variables.max_comment_size) {
      errors.push("Le nombre maximal de caractères est " + variables.max_comment_size);
    }
    return errors;
  }

  onClickDisplayComments(): void {
    this.displayInteractionBlock = "INTERACTION_TYPE_COMMENT";
    this.comment = true;
  }

  onClickDisplayLikes(): void {
    this.displayInteractionBlock = "INTERACTION_TYPE_LIKE";
    this.like = true
  }

  onClickDisplayShares(): void {
    this.displayInteractionBlock = "INTERACTION_TYPE_SHARE";
  }

  onClickShare(): void {
    this.modalReference = this.modalService.open(this.popupview, {size: 'lg'});
  }

  getShareDescription(event: string): void {
    this.shareDescription = event;
  }

  onClickPublishShare(): void {
    if (this.loggedAccount) {
      let interaction: InteractionCreateRequest = {} as InteractionCreateRequest;
      interaction.uuid = "";
      interaction.active = true;
      interaction.entity = this.post.uuid;
      interaction.emitter = this.loggedAccount.uuid;
      interaction.receiver = this.post.account?.uuid;
      interaction.content = this.shareDescription;
      interaction.interactionType = 'INTERACTION_TYPE_SHARE';

      if (this.post.shares) {
        this.post.shares++;
      } else {
        this.post.shares = 1;
      }

      this.interactionService.saveInteraction(interaction)
        .subscribe({
          next: response => {
            this.toastrService.success("La publication a été partagée avec succés", 'Partage publication');
            this.modalReference.close();
            window.location.reload();
          },
          error: error => {
            this.toastrService.error("Erreur lors du partage de la publication", 'Partage publication');
          },
          complete: () => {
          }
        });
    }
  }
}
