<div class="form-group">
  <label class="text-info" for="{{name}}">{{ title ? title : placeholderText }}</label>
  <span *ngIf="mandatory" class="text-danger">*</span>
  <ckeditor [(ngModel)]="value"
            (change)="handleChange($event)"
            (ngModelChange)="onInputChange($event)"
            [editor]="Editor"
            [maxlength]="maxInputLength"
            [config]="{placeholder: placeholderText}"
            style="border-radius: 6px"
            [ngClass]="{'is-valid': inputStatus=='valid','is-invalid': inputStatus=='invalid'}"
            name="content">
  </ckeditor>
</div>
