import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: 'app-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss']
})
export class RichTextComponent implements OnInit {

  @Input() value!: string;
  @Input() name: string = 'name';
  @Input() title: string = "";
  @Input() placeholderText!: string;
  @Input() inputStatus: string = '';
  @Input() maxInputLength: number = 2000;
  @Input() mandatory: boolean = false;
  public Editor = ClassicEditor;
  @Output() smInputEvent = new EventEmitter<string>();
  @Output() valueChange = new EventEmitter<string>();


  ngOnInit(): void {
  }

  handleChange(event: any): void {
    this.smInputEvent.emit(event.target.value);
  }

  onInputChange(event: string) {
    this.value = event;
    this.valueChange.emit(event);
  }

}
