import {Component, OnInit} from '@angular/core';
import {PostDetailsResource, PostService} from "ApiModulePost";
import {variables} from "../../../environments/variables";
import {Route} from "../../enums/route.enum";
import {LocalStorageService} from "../../services/local-storage.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-e-learning',
  templateUrl: './e-learning.component.html',
  styleUrls: ['./e-learning.component.scss']
})
export class ELearningComponent implements OnInit {
  posts: PostDetailsResource[] = []

  constructor(private postService: PostService, private localStorageService: LocalStorageService, private router: Router) {
  }

  ngOnInit(): void {

    this.postService.getTopActivePosts(10, 10).subscribe({
      next: response => {
        this.posts = response.resources!
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  onClickPost(post: PostDetailsResource): void {
    this.localStorageService.add(variables.post_details, post);
    this.localStorageService.add(variables.previous_route, Route.SEARCH);
    this.router.navigate([Route.POST_DETAILS], {queryParams: {id: post.uuid, more: true}});
  }

  protected readonly Route = Route;
}
