<div class="skillsmates-container">

  <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" style="padding-bottom: 20px;">
    <sm-image-text
      class="bg-white"
      [bgColor]="'#D3D3D3'"
      [routerLink]="[Route.DASHBOARD]"
      image="{{'left-arrow' | image}}"
      text="{{'post.return' | translate}}">
    </sm-image-text>
  </div>

  <div class="row">
    <div class="col text-center" style="margin: 15px;">
      <label class="title vertical-center"
             style="text-transform: none; color: black; font-weight: bold; font-size: large">E-Learning</label>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4"
         *ngFor="let post of posts">
      <sm-search-post [post]="post" (click)="onClickPost(post)"></sm-search-post>
    </div>
  </div>
</div>
